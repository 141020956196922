body{
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    overflow-x: hidden;
}
img{
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}
.block{
  > *:last-child{
    margin-bottom: 0;
  }
}
.fw-medium{
 font-weight: $font-weight-medium;   
}
.object-fit{
    object-fit: cover;
    object-position: center;
}
.page-wrapper{
    // min-height: calc(100vh - $headerHeight);
}
.font-secondary{
    font-family: $body-font-family;
}
.font-inter{
    font-family: 'Inter', sans-serif;
}
.font-poppins{
    font-family: $poppins-font-family;
}
.fw-800{
    font-weight: 800;
}
.fw-900{
    font-weight: 900;;
}

.text-black-secondary{
    color: $black-secondary;
}
.text-black-ternary{
    color: $black-ternary;
}
.text-nevy-blue{
    color: $nevy-blue;
}
.text-dark-green{
    color: $text-dark-green;
}
.text-gray{
    color: #8A8A8A;
}
.bg-light-gray{
    background-color: $light-gray;
}
.cursor-pointer{
    cursor: pointer;
}
.img-fit{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/* Starts css for Table CUstomization */
.table{
    td{
        font-weight: $font-weight-medium;
    }
    th{
        background-color: $primary;
        color: $white;

    }
}

// Global Classes
.minw-152{
    min-width: 152px;
}
.w-54{
    width: 54px;
    height: 54px;
}

/* Strtas Css customization of Modal */
.modal-header{
    background: #662168;
border-radius: 20px 20px 0px 0px;
}
/* Ends Css customization of Modal */
.mb-44{
    margin-bottom: 32px;
    @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
    }
}
.mb-54{
    margin-bottom: 54px;
    @media only screen and (max-width: 767px) {
        margin-bottom: 30px;
    }
}
.text-blue{
    font-weight: 900;
    line-height: 0.7;
    letter-spacing: 0.02em;
    color: #23346C;
    font-family: "Inter", sans-serif;
}
.minw-160{
    min-width: 120px;
}
.w-58{
    width: 58px;
    height: 58px;
}
.w-80{
    width: 80%;
}
.radius-36{
    border-radius: 36px;
}
.radius-30{
    border-radius: 30px;
}
.bg-purple{
    background: $primary;
}
.btn-sm{
    padding: 5px 30px;
    font-size: 14px;
    line-height: 20px;
}
.mb-10{
    margin-bottom: 10px;
}
.mb-20{
    margin-bottom: 20px;
    @media only screen and (max-width: 767px) {
        margin-bottom: 10px;
    }
}
.mb-30{
    margin-bottom: 30px;
    @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
    }
}
.mb-40{
    margin-bottom: 40px;
    @media only screen and (max-width: 767px) {
        margin-bottom: 25px;
    }
}
.container{
    // max-width: 1260px;
    @include media-breakpoint-down(xxl) { 
        width: 98%;
    }
}

.pb-100{
    padding-bottom: 100px;
    @media only screen and (max-width: 767px) {
        padding-bottom: 40px;
    }
}


.custom-label{
    width: 100%;
    #file{
        display: none;
    }
    #file1{
        display: none;
    }
    #file2{
        display: none;
    }
    #file3{
        display: none;
    }
    #file4{
        display: none;
    }
    #file5{
        display: none;
    }
    #file6{
        display: none;
    }
    .custom-input{
        display: flex;
        align-items: center;
        width: 100%;
        .icon{
          // width: 80px;
          display: flex;
          align-items:center;
          cursor: pointer;
          background:#662168;
          color: #fff;
          border-radius: 0 20px 20px 0;
          padding: 0;
          font-weight: 500;
          font-size:19px;
          line-height:52px;
          letter-spacing: 0.1px;
          color: #FFFFFF;
          width: 154px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family:"robotos" ;
          @media only screen and (max-width: 767px) {
            font-size:16px;
            width: 120px;
          }
        }
        .text{
            width: calc(100% - 154px);
            display: flex;
            align-items: flex-start;
            cursor: pointer;
            background: #e8e8e8;
            border-radius: 20px 0 0 20px;
            padding: 0 20px;
            font-size:19px;
            line-height:52px;
            @media only screen and (max-width: 767px) {
                width: calc(100% - 120px);
                font-size:16px;
            }
        }
    }
}



.custom-label-file{
    width: 100%;
    #file7{
        display: none;
    }
    #file8{
        display: none;
    }
    #file9{
        display: none;
    }
    #file10{
        display: none;
    }
    #file11{
        display: none;
    }
    #file12{
        display: none;
    }
    .custom-input-file{
        display: flex;
        align-items: center;
        width: 100%;
        display: block;
        width: 100%;
        padding: 10px 20px;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #000;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        appearance: none;
        border-radius: 40px;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        height: 46px;
        width: 100%;
        position: relative;
        .icon-file{
            background: #F4F3F3;
            position: absolute;
            left: 0;
            top: 0;
            padding: 5px 20px 4px;
            border-radius: 40px 0 0 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 44px;
        }
    }
}




.form-control{
    &.custom-control{
      padding:0 20px;
      border-radius:20px;
      font-weight: 400;
      font-size: 19px;
      line-height: 52px;
      letter-spacing: 0.02em;
      color: #722974;
      font-family: 'Poppins', sans-serif;
      background: #e8e8e8;
      @include placeholder{
          color: #722974;
      }
    }
}
.tc{
    max-width: 920px;
    margin: 0 auto 40px;
}

.episode-card{
  background: #FDFFFC;
  border-radius: 20px;
  padding:23px 19px;
  h2{
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 39px;
      color: #712973;
  }
  h3{
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #011627;
  }
  ul{
      padding-left:0;
      margin-bottom:0;
      list-style-type:none;
      text-align:left;
      li{
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 30px;
          letter-spacing: 0.02em;
          color: #011627;
          display: flex;
          align-items: center;
      }
  }
  p{
      text-align:left;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 30px;
      letter-spacing: 0.02em;
      color: #011627;
  }
}
.episodes{
    background:#e8e8e8;
}


// table scss
.leader-table{
    border: none;
    thead{
        tr{
            border: none;
            th{
                border: none;
                &:first-child{
                    border-top-left-radius: 20px;
                }
                &:last-child{
                    border-top-right-radius: 20px;
                }
            }
        }
    }
    tbody{
        tr{
            border: 1px solid #e8e8e8;
            background: $white;
            td{
                border: none;
                padding: 28px 20px;
                background: none;
            }
            &:nth-child(odd){
                background:#EAE8E8;
            }
            &:nth-child(even){
                background: $white;
            }
        }
    }
}

.date-wrapper{
    display: flex;
    @media only screen and (max-width: 768px) {
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
     }
}
.react-datepicker__time-container{
    @media only screen and (max-width: 768px) {
        margin-left: 0 !important;
        margin-top: 20px;
        float: left;
    }
}

.game-logo{
    width: 150px;
    margin: 0 auto 40px;
    @media only screen and (max-width: 768px) {
        width: 150px;
     }
}
.ct-container-700{
    max-width: 700px;
    margin: 0 auto;
    @media only screen and (max-width: 768px) {
        margin: 30px auto;
        padding: 0 20px;
     }
}
.ct-container-600{
    max-width: 600px;
    margin: 0 auto;
    @media only screen and (max-width: 768px) {
        margin: 30px auto;
        padding: 20px 20px 50px;
     }
     &.p-70{
        padding: 25px 20px 50px;
     }
}
.ct-container-920{
    max-width: 920px;
    margin: 0 auto;
    @media only screen and (max-width: 768px) {
        margin: 30px auto;
        padding: 0 20px;
     }
     .ct-container-600{
        @media only screen and (max-width: 768px) {
            margin: 0 auto;
            padding: 20px 0;
         }
     }
     &.bg-gray{
         background:#F6F6F6;
     }
}
.bg-white{
    background: $white;;
}
.mt-60{
    margin-top: 60px;
    @media only screen and (max-width: 768px) {
       margin-top: 30px; 
    }
}
.mt-250{
    margin-top: 250px;
    @media only screen and (max-width: 768px) {
        margin-top: 50px; 
     }
}
.p-70{
    padding: 50px 0 50px;
    @media only screen and (max-width: 768px) {
        padding: 25px 0; 
     }
}
.game-btns{
    display: flex;
    align-items: center;


    @media only screen and (max-width: 768px) {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;
    }
   
    ul{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0;
        width: 100%;
        @media only screen and (max-width: 768px) {
            justify-content: space-between;
            padding: 24px;
            margin-bottom: 0; 
            background: #d9d9d9;
            width: 100%;
        }
        @media only screen and (max-width: 576px) {
            padding: 12px;
        }
        .gm-outer{
            background: #6A1F6C;
            width: 104px;
            height: 104px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 30px;
            margin-right: 40px;
            @media only screen and (max-width: 768px) {
                width: 98px;
                height: 98px;
                margin-right: 0;
                background: transparent
            }
            @media only screen and (max-width: 576px) {
                width: 70px;
                height: 70px;
                border-radius: 20px;
            }
            &:last-child{
                margin-right: 0;
            }
            .gm-btn{
                width: 93px;
                height: 93px;
                border: 1px dashed #FFFFFF;
                border-radius: 30px;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                text-decoration: none;
                svg{
                    path{
                    fill:#fff;
                    @media only screen and (max-width: 768px) {
                        fill:#000;
                     }
                    }
                    @media only screen and (max-width: 576px) {
                        width: 35px;
                     }  
                }
                @media only screen and (max-width: 768px) {
                   border: none;
                   width: 70px;
                   height: 70px;
                }
                span{
                    img{
                        @media only screen and (max-width: 576px) {
                            width: 35px;
                         }
                    }
                }
                .text{
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 32px;
                    letter-spacing: 0.02em;
                    color: #FFFFFF;
                    @media only screen and (max-width: 768px) {
                        color: $black;
                     }
                    @media only screen and (max-width: 576px) {
                        font-size: 16px;
                        line-height: 22px;
                    }
                     
                }
                &:hover{
                    text-decoration: none;
                }
            }
            &.active{
                background: $secondary;
                @media only screen and (max-width: 768px) {
                    background: $primary;
                 }
                 .gm-btn{

                    svg{
                        path{
                            fill:#fff;
                        }
                    }
                    .text{
                        color:$white;
                    }
                }
            }
        }
    }
}


.flexible-card{
    padding: 52px 32px;
    // background: #FDFFFC;
    background: $white;
    border-radius: 0px 50px 0 50px;
    text-align:left;
    font-family: $headings-font-family;
    h3{
        font-weight: 500;
        font-size: 22px;
        line-height: 31px;
        color: #011627;
    }
    p{
        font-weight: 400;
        font-size: 16px;
        line-height: 1.3;
        text-align:left;
        letter-spacing: 0.02em;
        color: #011627;
    }
    .status{
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        display: block;
        &.orange{
            color:#FF1D03;
        }
        &.green{
            color: #1D8942;
        }
    }

    // &.active{
    //     background: $primary;
    //     h3{
    //         color: $white;
    //     }
    //     p{
    //         color: $white !important;
    //     }
    //     .status{
    //         color: $white;
    //     }
    // }
     &:hover{
        background: $primary;
        h3{
            color: $white;
        }
        p{
            color: $white !important;
        }
        .status{
            color: $white;
        }
        .icon{
            svg{
                path{
                    fill: $white;
                }
            }
        }
    }
    &.reverse{
       border-radius: 50px 0 50px 0;
    }
}




// .bill-attach{
//     width: 100%;
//     #uploadbill{
//       display: none;
//     }
//     label{
//       width: 100%;
//       span{
//         display: block;
//         width: 100%;
//         height: 54px;
//         padding: 0.625rem 1.5rem;
//         font-family: "Be Vietnam", sans-serif;
//         font-size: 1.125rem;
//         font-weight: 700;
//         line-height: 1.25;
//         color: #1B2850;
//         background-color: rgba(165, 166, 182, 0.05);
//         background-clip: padding-box;
//         border: 1px solid rgba(165, 166, 182, 0.05);
//         border-radius: 0.25rem;
//         -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
//         transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
//         transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
//         transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
//       }
//     }
//   }

// .right-icon {
//       .control-wrap {
//         position: relative;
//         .form-control {
//           padding: 10px 45px 10px 24px;
//         }
//         .has-icon-right {
//           position: absolute;
//           right: 20px;
//           top: 20px;
//           cursor: pointer;
//           .file-upload {
//             cursor: pointer;
//             #attach-file {
//               display: none;
//             }
//             #attach-check {
//               display: none;
//             }
//             #attach-check-freefuelcard {
//               display: none;
//             }
//             #attach-check-insurance {
//               display: none;
//             }
//           }
//         }
//       }
    // }


    // .custom-file{
    //     &.add{
    //         .custom-file-input{
    //             &:lang(en){
    //                 ~ .custom-file-label{
    //                     height: calc(1.25em + 1rem + 6px);
    //                     background:#fff;
    //                     border-color:#e8e8e8;
    //                     &:focus{
    //                         outline: none;
    //                     }
    //                     &::after{
    //                         content:'+';
                           
    //                         background-color: $primary;
    //                         color:$white;
    //                         height: calc(1.25em + 1.25rem);
    //                         padding: .6rem .75rem;
    //                     }   
    //                 }
    //             }
    //         }
    //         #SiteLogo{
    //             display: none;
    //         }
    //         .custom-file-label{
    //             height: calc(1.25em + 1rem + 6px);
    //             background: #fff;
    //             border-color: #e8e8e8;
    //             z-index: 0;
    //             &:focus{
    //                 outline: none;
    //             }
    //             &::after{
    //                 content:'+';
                   
    //                 background-color: $primary;
    //                 color:$white;
    //                 height: calc(1.25em + 1.25rem);
    //                 padding: .6rem .75rem;
    //             }
    //         }   
    //     }
    // }


    .custom-label{
        width: 100%;
        margin:30px 0;
        #file{
            display: none;
        }
        .custom-input{
            display: flex;
            align-items: center;
            width: 100%;
            padding: 0;
            .icon{
              // width: 80px;
              display: flex;
              align-items:center;
              cursor: pointer;
              background:#662168;
              color: #fff;
              border-radius: 0 20px 20px 0;
              padding: 0;
              font-weight: 500;
              font-size:19px;
              line-height:52px;
              letter-spacing: 0.1px;
              color: #FFFFFF;
              width: 154px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-family:"robotos" ;
              @media only screen and (max-width: 767px) {
                font-size:16px;
                width: 120px;
              }
            }
            .text{
                width: calc(100% - 154px);
                display: flex;
                align-items: flex-start;
                cursor: pointer;
                background: #e8e8e8;
                border-radius: 20px 0 0 20px;
                padding: 0 20px;
                font-size:19px;
                line-height:52px;
                @media only screen and (max-width: 767px) {
                    width: calc(100% - 120px);
                    font-size:16px;
                }
            }
        }
    }
  
  
  
    .form-control{
        &.custom-control{
          padding:0 20px;
          border-radius:20px;
          font-weight: 400;
          font-size: 19px;
          line-height: 52px;
          letter-spacing: 0.02em;
          color: #722974;
          font-family:"poppins" ;
          background: #e8e8e8;
          @include placeholder{
              color: #722974;
          }
        }
    }
  
  
    .episode-card{
      background: #FDFFFC;
      border-radius: 20px;
      padding:23px 19px;
      h2{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 700;
          font-size: 26px;
          line-height: 39px;
          color: #712973;
      }
      h3{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 17px;
          line-height: 30px;
          text-align: center;
          letter-spacing: 0.02em;
          color: #011627;
      }
      ul{
          padding-left:0;
          margin-bottom:0;
          list-style-type:none;
          text-align:left;
          li{
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 600;
              font-size: 15px;
              line-height: 30px;
              letter-spacing: 0.02em;
              color: #011627;
          }
      }
      p{
          text-align:left;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 30px;
          letter-spacing: 0.02em;
          color: #011627;
      }
    }
    .episodes{
        background:#e8e8e8;
    }
  
  
  
    .btn-close{
        padding: 0;
        width: 25px;
        height: 25px;
        position: relative;
        @include border-radius(50%);
        background-color: #FA741B;
        opacity: 1;
        @media (max-width: 768px) {
            width: 20px;
            height: 20px;
        }
        &:hover{
            opacity: 0.8;
        }
    }
  
  
    .add-btn{
        width: 40px;
        height:40px ;
        border: 1px solid $white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
    }
    .with-btn{
      display: flex;
      align-items: center;
        .form-control{
            width: calc(100% - 60px);
        }
    }
  
  
  
    .time-section{
        display: flex;
        align-items: center;
        justify-content: center;
        h4{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 53px;
          letter-spacing: 0.02em;
          color: #722974;
          
        }
        .hours{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 34px;
          line-height: 53px;
          letter-spacing: 0.02em;
          color: $white;
          width: 80px;
          height: 80px;
          background: $primary;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }
        .devide{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 34px;
          line-height: 53px;
          letter-spacing: 0.02em;
          color: $primary; 
          display: block;
          margin-top: -60px;
        }
        .minute{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          font-size: 34px;
          line-height: 53px;
          letter-spacing: 0.02em;
          color: $white;
          width: 80px;
          height: 80px;
          background: $primary;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }
    }
  
  .with-send-btn{
      position:relative;
    .send-btn{
          background:$primary;
          position: absolute;
          right: 0;
          top: 0;
          padding: 11px 20px 10px;
          border-radius: 0 40px 40px 0;
          display:flex;
          align-items:center;
          justify-content:center;
    }
    .form-control{
      padding-right: 76px;
    }
  }
  
  .with-left-btn{
      position:relative;
    .icon-btn{
          background:#F4F3F3;
          position: absolute;
          left: 1px;
          top: 1px;
          padding: 5px 20px 4px;
          border-radius: 40px 0 0 40px;
          display:flex;
          align-items:center;
          justify-content:center;
          height: 44px;
    }
    .file-label{
      padding-left: 76px;
      display: block;
      width: 100%;
      padding: 10px 20px;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #000;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      appearance: none;
      border-radius: 40px;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      height: 46px;
    }
  }
  
  
  .cmn-gray-card{
      padding:44px 112px;
      background: #F1F1F1;
      border-radius: 30px;
      @media (max-width: 768px) {
        padding:25px;
        }
      h2{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 900; 
          line-height: 38px;
          color: #000000;
          @media (max-width: 768px) {
            line-height: 30px;
            }
      }
      h3{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 600;
          line-height: 26px;
          color: #000000;
          @media (max-width: 768px) {
            line-height: 26px;
        }
      }
      p{
          font-family: 'Poppins';
          font-style: normal;
          font-weight: 400;
          line-height: 26px;
          color: #000000;
          @media (max-width: 768px) {
            line-height: 26px;
        }
      }
  }
  .custom-radiobx{
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 600;
    letter-spacing: 0.02em;
    color: #757575;
  }
  .text-orangedark{
    color: #FA741B;
  }
  .text-lighgrey{
    color: #757575;
  }
  .font-italic{
    font-style: italic;
  }
  .accordion{
      .accordion-item{
          border-radius:10px;
          border:1px solid #d3d3d3;
          overflow: hidden;
          margin-bottom: 12px;
      }
      .accordion-button{
          box-shadow:none;
          font-family: 'Poppins';
          font-weight: 500;
          color: $black-secondary;
          padding:12px 24px;
          font-size: inherit;
          &::after{
              width: 23px;
              height: 23px;
              border: 1.5px solid #D7D7D7;
              border-radius: 6px;
              background-size: 16px;
              background-position: center;
          }
        
          &:focus{
              box-shadow:none;
          }
      }
      .accordion-body{
        padding: 0 24px 20px 24px;
      }
  }


  .accordion-item:last-of-type .accordion-button.collapsed{
    border-bottom-left-radius: 40px !important;
    border-bottom-right-radius: 40px !important;
  }
  
  
  // .progressbar {
  //     counter-reset: step;
  //   }
  //   .progressbar li {
  //     list-style: none;
  //     display: inline-block;
  //     width: 30.33%;
  //     position: relative;
  //     text-align: center;
  //     cursor: pointer;
  //   }
  //   .progressbar li:before {
  //     content: counter(step);
  //     counter-increment: step;
  //     width: 30px;
  //     height: 30px;
  //     line-height : 30px;
  //     border: 1px solid #ddd;
  //     border-radius: 100%;
  //     display: block;
  //     text-align: center;
  //     margin: 0 auto 10px auto;
  //     background-color: #fff;
  //   }
  //   .progressbar li:after {
  //     content: "";
  //     position: absolute;
  //     width: 100%;
  //     height: 1px;
  //     background-color: #ddd;
  //     top: 15px;
  //     left: -50%;
  //     z-index : -1;
  //   }
  //   .progressbar li:first-child:after {
  //     content: none;
  //   }
  //   .progressbar li.active {
  //     color: green;
  //   }
  //   .progressbar li.active:before {
  //     border-color: green;
  //   } 
  //   .progressbar li.active + li:after {
  //     background-color: green;
  //   }
  
  
  .stepper-wrapper {
      margin-top: auto;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }
    .stepper-item {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
    
      @media (max-width: 768px) {
        font-size: 12px;
      }
    }
    
    .stepper-item::before {
      position: absolute;
      content: "";
      border-bottom: 8px solid #dfdfdf;
      width: 100%;
      top: 9px;
      left: 0;
      z-index: 2;
      
    }
    
    .stepper-item::after {
      position: absolute;
      content: "";
      border-bottom: 8px solid #dfdfdf;
      width: 100%;
      top: 9px;
      left: 0;
      z-index: 2;
      
    }
    
    .stepper-item .step-counter {
      position: relative;
      z-index: 5;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background: #dfdfdf;
      margin-bottom: 6px;
    }
    
    .stepper-item.active {
      font-weight: bold;
    }
    
    .stepper-item.completed .step-counter {
      background-color: #FA741B;
    }
    
    .stepper-item.completed::after {
      position: absolute;
      content: "";
      border-bottom: 8px solid #FA741B;
      width: 100%;
      top: 9px;
      left: 0;
      z-index: 3;
     
    }
    
    .stepper-item:first-child::before {
        border-radius: 30px 0 0 30px;
    }
    .stepper-item:last-child::after {
        border-radius: 0 30px 30px 0;
    }
    .stepper-item:first-child.completed::after{
        border-radius: 30px 0 0 30px;
    }
    .stepper-item:last-child::before{
        border-radius: 0 30px 30px 0;
    }
  
  
  
  
  
  
  
  
    .custom-radio label {
      margin: 0 10px 10px 0;
      padding: 0 0 0 26px;
      cursor: pointer;
      position: relative;
      display: inline-block;
      text-transform: capitalize;
    }
    .custom-radio input[type="radio"] {
      display: none;
    }
    .custom-radio input[type="radio"] + span {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      position: absolute;
      left: 0;
      top: -4px;
      background: #fff;
      border: 2px solid #25313D;
      border-radius: 50%;
      opacity: 0.5;
    }
    .custom-radio input[type="radio"] ~ span:after {
      display: none;
      position: absolute;
      content: "";
    }
    .custom-radio input[type="radio"]:disabled + span {
      opacity: 0.7;
    }
    .custom-radio input[type="radio"]:checked + span {
      background: #fff;
      border: 5px solid #3a2d27;;
      transition: all 0.3s ease-in-out;
      opacity: 1;
    }
    
  
  
    .delivery-card{
        border-radius: 20px;
        background: $white;
        @media (max-width: 768px) {
           margin-bottom: 20px;
        }
        .card-head{
            height: 168px;
            border-radius: 20px 20px 0 0;
            background: #eeeeee;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .card-content{
            padding: 17px 19px 30px;
            h3{
              font-family: 'Poppins';
              font-style: normal;
              font-weight: 700;
              font-size: 20px;
              line-height: 30px;
              color: #712973;
              @media (max-width: 768px) {
                  font-size: 16px;
                  line-height: 22px;
              }
            }
            p{
              font-family: 'Inter';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 23px;
              letter-spacing: 0.02em;
              color: #011627; 
              @media (max-width: 768px) {
                font-size: 14px;
                line-height: 20px;
                } 
            }
        }
    }
    



    .cmd-title{
        h2{
            font-weight: 600;
            line-height: 27px;
            text-align: center;
            color: #5E6282;
            font-family: 'Poppins', sans-serif;
            @media only screen and (max-width: 767px) {
                line-height: 24px;
            }
        }
        h3{
            font-weight: 900;
            line-height: 53px;
            letter-spacing: 0.02em;
            color: #23346C;
            font-family: $headings-font-family;
            @media only screen and (max-width: 767px) {
                line-height: 40px;
            }
        }
        .send-text{
            font-weight: 400;
            font-size: 15px;
            line-height: 53px;
            letter-spacing: 0.02em;
            font-family: 'Poppins', sans-serif;
            color: #838282;
            margin-top: 5px;
        }
    }




    .about-us-section{
        .left-part{
            @media only screen and (max-width: 767px) {
                justify-content: center !important;
                
            }
            img{
                max-width: 452px;
                width: 100%;
                max-height: 559px;
                height: 100%;
                @media only screen and (max-width: 767px) {
                    max-width: 100%;
                    width: 100%;
                    max-height: 100%;
                }
            }
        }
        .ct-550{
            max-width: 550px;
        }
        .right-part{
            padding-left: 30px;
            @media only screen and (max-width: 767px) {
                padding: 0 25px;
                margin-top: 20px;
            }
            p{
                font-family: $headings-font-family;
                font-weight: 400;
                line-height: 32px;
                /* or 139% */
                letter-spacing: 0.02em;
                color: #011627;
                @media only screen and (max-width: 767px) {
                    line-height: 24px;
                }
            }
        }
    }



    .category-details{
        max-width: 907px;
        margin: 0 auto;
        .category-image{
            position: relative;
            margin-bottom: 50px;
            .quest-text{
                position: absolute;
                bottom: -36px;
                right: 0;
                padding: 25px;
                font-weight: 700;
                font-size: 26px;
                line-height: 115%;
                /* identical to box height, or 46px */
                text-align: center;
                text-transform: capitalize;
                color: #FDFFFC;
                font-family: 'Poppins', sans-serif;
                background: $primary;
                @media only screen and (max-width: 767px) {
                    bottom: -22px;
                    right: 0;
                    padding: 12px;
                    font-size: 14px;
                }
            }
        }
        p{
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            /* or 139% */
            letter-spacing: 0.02em;
            color: #011627;
            font-family: $headings-font-family;
            @media only screen and (max-width: 767px) {
                font-size: 16px;
                line-height: 26px;
            }
        }
    }



    .episode-section{
        padding: 100px 0;
        position: relative;
        background:#FFF1DA ;
        @media only screen and (max-width: 991px) {
            padding: 70px 0;
        }
        &.bg-pink{
            background:#FFF1DA ;
        }
        .slick-slide {
            padding: 0 14px;
        }
        .slick-list {
            margin: 0 -14px;
        }
        .slick-dots li button:before{
            content: '';
            width: 20px;
            height: 20px;
            border: 1px solid #712973;
            border-radius: 50%;
            background: #fff;
            opacity: 1;
        }
        .slick-dots li.slick-active button:before{
            background: #712973;
        }
        .slick-dots{
            bottom: -55px;
        }
        
    }

    .episode-slider{
        &.pink-curve{
            &::before{
                content: '';
                display: block;
                width: 100%;
                background: url('../../../public/assets/images/pink-curve.jpeg') no-repeat;
                height: 258px;
                background-size: cover;
                z-index: 0;
                vertical-align: top;
                display: inline-block;
                
                @media only screen and (max-width: 767px) {
                    height: 106px;
                }
            }
        }
    }



    .progress-block{
        > *:first-child{
            overflow: visible !important;
        }
        span{
            width: 48px;
            height: 48px;
            display: inline-flex !important;
            align-items: center;
            justify-content: center;
            background: rgb(250, 116, 27);
            border-radius: 50%;
            @media only screen and (max-width: 767px) {
                display: none !important;
            }
        }
    }

    .top-curverimg{
        position: relative;
        background: #E0FEFB;
        &::before{
            content: '';
            display: block;
            width: 100%;
            background: url('../../../public/assets/images/sky-curve.jpeg') no-repeat;
            height: 258px;
            background-size: cover;
            // position: absolute;
            // left: 0;
            // right: 0;
            // top: 0;
            display: inline-block;
            z-index: 0;
            @media only screen and (max-width: 767px) {
                height: 106px;
            }
        }
    }
    .popular-cards{
       margin-top: -68px;
        @media only screen and (max-width: 767px) {
            margin-top: 0;
        }
        h2{
            font-weight: 700;
            line-height: 115%;
            /* identical to box height, or 67px */
            text-align: center;
            text-transform: capitalize;
            color: $primary;
        }
    }

    .purple-curve{
        background-color: #712973;
    }
    .banner-purple-bg{
        position: relative;
        &::after{
            content: '';
            display: block;
            width: 100%;
            background: url('../../../public/assets/images/purple-curve.jpeg') no-repeat;
            height: 258px;
            background-size: cover;
            // position: absolute;
            // left: 0;
            // right: 0;
            // bottom: -250px;
            display: inline-block;
            z-index: 0;
            position: relative;
            top: -2px;
            @media screen and (min-width: 1199px) {
                position: relative;
                top: 2px;

            }
            @media only screen and (max-width: 767px) {
                height: 106px;
            }
        }
    }

.sliderblock{
    position: relative;
    // &::after{
    //     @media screen and (min-width: 1199px){
    //         content: '';
    //         position: absolute;
    //         left:auto;
    //         width: 150px;
    //         right: 0;
    //         top: 0;
    //         bottom: 30px;
    //         background-color: rgba($white, 0.5);
    //     }
    // }
}
.banner-sectionblock{
    @media screen and (min-width: 1199px) {
        margin-bottom: -260px;
        
    }
    > div{
        position: relative;
        z-index: 1;
    }
}

    .team-section{
        position: relative;
        &.pink-curve{
            background:#FFF1DA ;
            &::before{
                content: '';
                display: block;
                width: 100%;
                background: url('../../../public/assets/images/pink-curve.jpeg') no-repeat;
                height: 258px;
                background-size: cover;
                // position: absolute;
                // left: 0;
                // right: 0;
                // top: 0;
                z-index: 0;
                display: inline-block;
                @media only screen and (max-width: 767px) {
                    height: 106px;
                }
            }
        }
        .team-cards{
            position: relative;
            h2{
                font-weight: 700;
                font-size: 36px;
                line-height: 115%;
                /* identical to box height, or 67px */
                text-align: center;
                text-transform: capitalize;
                color: $primary;
                @media only screen and (max-width: 767px) {
                    font-size: 24px;
                }
            }
            p{
                font-weight: 400;
                font-size: 23px;
                line-height: 32px;
                /* or 139% */
                text-align: center;
                letter-spacing: 0.02em;
                color: #011627;
                font-family: $headings-font-family;
                @media only screen and (max-width: 767px) {
                    font-size: 16px;
                }
            }
        }
        .slick-slide {
            padding: 0 30px;
            width: 290px;
           
        }
        .slick-list {
            margin: 0 -30px;
        }
        
    }

    .team-slider{
        .slick-list{
            padding-bottom: 30px !important;
        }
    }
    .quest-card-logo svg {
        width: 6rem;
        height: auto;
    }
    .flexible-section{
        &.pink-curve{
            background:#FFF1DA ;
            &::before{
                content: '';
                display: block;
                width: 100%;
                background: url('../../../public/assets/images/pink-curve.jpeg') no-repeat;
                height: 258px;
                background-size: cover;
                z-index: 0;
                display: inline-block;
                @media only screen and (max-width: 767px) {
                    height: 106px;
                }
            }
        }
      
        .slick-slide {
            padding: 0 15px;
            width: 290px;
            &.active{
                transform: translate(0, 23px);
                transition: all .3s ease-in-out;
                .flexible-card{
                    transform: translate(0, 23px);
                    transition: all .3s ease-in-out;
                }
            }
            &:hover{
                .flexible-card{
                    transform: translate(0, 23px);
                    transition: all .3s ease-in-out;
                }
            }
        }
        .slick-list {
            margin: 0 -15px;
        }
    }


    .choose-img-info{
        display: flex;
        align-items: flex-start;
        position: relative;
        @media only screen and (max-width: 767px) {
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }
        // &::after{
        //     content: "";
        //     // background: url(/./public/assets/images/arrorw.svg);
        //     width: 245px;
        //     height: 103px;
        //     position: absolute;
        //     bottom: 0;
        //     left: 0;
        // }
    }
    // .choose-img{
    //     max-width: 690px;
    //     @media only screen and (max-width: 767px) {
    //         max-width: 350px;
    //     }
    // }
    .choose-details{
        width: 50%;
        padding: 89px 57px 62px;
        background: #EAFFFD;
        text-align: left;
        margin-top: 80px;
        // margin-left: -102px;
        @media only screen and (max-width: 767px) {
            margin-left: 0;
            padding:22px;
            width: 100%;
            margin-top: 30px;
        }
        h4{
            font-weight: 500;
            line-height: 35px;
            letter-spacing: 0.02em;
            text-transform: capitalize;
            color: #434343;
            text-align: left;
            margin-bottom: 23px;
            @media only screen and (max-width: 767px) {
                line-height: 24px;
            }
        }
        h3{
            font-style: normal;
            font-weight: 400;
            color: #3B3B3B;
            margin-bottom: 10px;
            @media only screen and (max-width: 767px) {
                line-height: 34px;
            }
        }
        p{
            font-weight: 400;
            line-height: 32px;
            letter-spacing: 0.02em;
            color: #434343;
            @media only screen and (max-width: 767px) {
                line-height: 24px;
            }
        }
        .count-block{
            font-weight: 900;
            font-size: 198px;
            line-height: 32px;
            letter-spacing: 0.02em;
            color: #5D5E5D;
            opacity: 0.2;
            font-family: 'Poppins', sans-serif;
            position: absolute;
            left: 0;
            top: 13px;
        }
        .choose-text{
            position:relative;
        }
    }
.choose-img-info{
    @media only screen and (max-width: 767px) {
        margin-bottom: 20px;
    }
    .choose-image{
        position: relative;
        z-index: 0;
        width: calc(50% + 102px);
        margin-right: -102px;
        @media only screen and (max-width: 767px) {
                width: 100%;
                margin-right: 0;
            }
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        &::after{
            content:"";
            display: inline-block;
            width: 220px;
            height: 245px;
            margin-top: -47px;
            background: url('../../../public/assets/images/arrow-new.svg') no-repeat;
            @media only screen and (max-width: 767px) {
                content: none;
            }
        }
    }
    .choose-details{
        z-index: 1;
    }
    &.even{
        .choose-image{
            margin-left: -102px;
            margin-right: 0;
            @media only screen and (max-width: 767px) {
                margin-left: 0;
            }
            &::after{
                transform: scaleX(-1);
            }
        }
    }
    &:last-child{
        .choose-image{
            &::after{
                content: none;
            }
        }
    }
}
 

.login-blocks{
    h1{
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        font-family: 'Poppins', sans-serif;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    ul{
        padding: 10px 17px;
        border-radius: 43px;
        background: $primary;
        display: flex;
        align-items: center;
        gap: 10px;
        li{
            width: calc(50% - 10px);
            list-style-type: none;
            a{
                padding: 10px 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                color:$white;
                text-decoration: none;
                background: transparent;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                font-family: 'Poppins', sans-serif;
                &.active{
                    background: $white;
                    color: $primary;
                    border-radius: 43px;
                }
            }
        }
    }
}
.trending-slider{
    // .slick-slider {
    //     margin:-15px;
    // }
    // .slick-slide {
    //     padding:10px;
    // }
    .slick-slide {
        padding: 2px;
        width: 314px;
    }
    .slick-list {
        margin: -2px;
    }
}


.company-testimonialsection{
    h2{
        font-weight: 400;
        line-height:52px;
        /* or 150% */
        color: #090305;
        font-family: $headings-font-family;
        @media only screen and (max-width: 767px) {
            line-height: 30px;
        }
    }
    .slick-arrow{
        &.slick-next{
            top: -42px;
            right: 15px;
            @media only screen and (max-width: 576px) {
                top: -11px;
            }
            &::before{
                content: "";
                background: url('../../../public/assets/images/arrow-right.svg') no-repeat;
                position: absolute;
                width: 36px;
                height: 39px;
               
            }
        }
        &.slick-prev{
            top: -40px;
            left: inherit;
            right: 80px;
            @media only screen and (max-width: 576px) {
                top: -9px;
            }
            &::before{
                content: "";
                background: url('../../../public/assets/images/arrow-left.svg') no-repeat;
                position: absolute;
                width: 36px;
                height: 39px;
            }
        }
    }
}



.modal-header{
    .modal-title{
        font-weight: 600;
        font-size: 24px;
        line-height:115%;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        font-family: 'Poppins', sans-serif;
        @media only screen and (max-width: 767px) {
            font-size: 20px;
        }
    }
}

.date-modal{
    .modal-dialog{
        max-width: 1026px;
        width: 95%;
    }
    .modal-body{
        padding: 50px 100px;
        @media only screen and (max-width: 767px) {
            padding: 30px 20px;
        }
        @media only screen and (max-width: 576px) {
            padding: 30px 10px;
        }
    }
}

.maxw-900{
    max-width: 620px;
}

    .modal-set-wrapper .stepper-wrapper {
        margin-top: auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
      }
      .modal-set-wrapper .stepper-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
      
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
      
      .modal-set-wrapper .stepper-item::before {
        position: absolute;
        content: "";
        border-bottom: 2px solid #621E64;
        width: 100%;
        top: 32px;
        left: 0;
        z-index: 2;
        @media only screen and (max-width: 767px) {
            top: 41px;
        }
      }
      
      .modal-set-wrapper .stepper-item::after {
        position: absolute;
        content: "";
        border-bottom: 2px solid #621E64;
        width: 100%;
        top: 32px;
        left: 0;
        z-index: 2;
        @media only screen and (max-width: 767px) {
            top: 41px;
        }
      }
      
      .modal-set-wrapper .stepper-item .step-counter {
        position: relative;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background:transparent;
        margin-bottom: 6px;
      }
      
      .modal-set-wrapper .stepper-item.active {
        font-weight: bold;
      }
      
      .modal-set-wrapper .stepper-item.completed .step-counter {
        background-color: $white;
      }
      
      .modal-set-wrapper .stepper-item.completed::after {
        position: absolute;
        content: "";
        border-bottom: 7px solid $white;
        width: 100%;
        top: 29px;
        left: 50%;
        z-index: 3;
        @media only screen and (max-width: 767px) {
            top: 38px;
            border-bottom: 5px solid $white;
        }
      }
      .modal-set-wrapper .stepper-item.completed::before {
        position: absolute;
        content: "";
        border-bottom: 7px solid $white;
        width: 100%;
        top: 29px;
        left: 0;
        z-index: 3;
        @media only screen and (max-width: 767px) {
            top: 38px;
            border-bottom: 5px solid $white;
        }
      }
      
      .modal-set-wrapper .stepper-item:first-child::before {
        content: "";
      }
      .modal-set-wrapper .stepper-item:last-child::after {
        content: "";
      }
      .modal-set-wrapper{
        .step-name{
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 1.4;
            text-transform: capitalize;
            color: $white;
            font-family: 'Poppins', sans-serif;
            @media only screen and (max-width: 767px) {
                font-size: 13px;
                max-width: 60px;
                text-align: center;
            }
            @media only screen and (max-width: 576px) {
                font-size: 11px;
            }
          }
          .stepper-item:not(.completed):not(.active){
            .step-name{
                color: #621E64;
            }
          }
      }



      .event-text{
        display: flex;
        align-items: center;
        justify-content: space-between;
        span{
            font-weight: 700;
            font-size: 16px;
            line-height: 30px;
            text-transform: capitalize;
            color: $white;
            font-family: 'Poppins', sans-serif;
            @media only screen and (max-width: 767px) {
                font-size: 12px;
                line-height: 20px;
            }
        }
      }


      .custom-input{
        padding:0 20px;
        border-radius:20px;
        font-weight: 400;
        font-size: 19px;
        line-height: 52px;
        letter-spacing: 0.02em;
        color: #722974;
        font-family: 'Poppins', sans-serif;
        background: #e8e8e8;
      }




          .with-icon{
              display: flex;
              align-items: center;
              .plus-btn{
                  width: 54px;
                  margin-left: 10px;
              }
              .form-control{
                  width: 70%;
              }
          }
   


          .navbar-toggler{
              border: none;
              .navbar-toggler-icon{
                background-image: none;
                position: relative;
                width: 24px;
                height: 20px;
                &::after{
                    content: "";
                    top: 0;
                    left: 0;
                    background: url(../../../public/assets/images/mobile-menu.svg)no-repeat;
                    width: 30px;
                    height: 30px;
                    display: inline-block;
                }
              }
              &:focus{
                  box-shadow: none;
              }
          }



.react-datepicker-popper{
    position:relative !important;
    transform:none !important;
}
.react-datepicker-wrapper{
    display:none !important;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before{
    border:none;
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
    border:none;
}
.react-datepicker__header{
    padding: 0;
    background-color: transparent;
    border: 0;
    .react-datepicker__current-month{
        background-color: $secondary;
        padding:19px 0;
    }
}
.react-datepicker__time-list-item {
    display: flex;
    align-items: center;
    color: $primary !important;
    &::before{
        content: '';    
        display: block;
        width: 10px;
        height: 10px;
        border: 1px solid $primary;
        background-color: $primary;
        @include border-radius(50%);
        margin-right: 10px;
        flex-shrink: 0;
    }
    &.react-datepicker__time-list-item--selected{
        background-color: transparent !important;
        &::before{
            background-color: transparent;
        }
    }
}
.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button){
    left: calc(100% - 200px);
}
.react-datepicker__month-container{
    @include border-radius(20px);
    overflow: hidden;
    background-color: #fff;
}
.react-datepicker{
    background-color: transparent;
    border: 0;
    padding: 0;
}
.react-datepicker__time-container {
    margin-left: 30px;
    min-width: 140px;
    @include border-radius(20px);
    overflow: hidden;
    .react-datepicker__header--time{
        padding: 10px 10px;
        background-color: $secondary;
        color: #fff;
    }
}
.react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before{
    top:20px;
    border-color: $white;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    color: #fff;
    font-size: 20px;
    line-height: 20px;
}
.react-datepicker__day-name{
    color: $white;
    background: $primary;
    border-radius: 50%;
}
.react-datepicker__day-names{
    margin-top: 10px;
}
.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
    background-color: $secondary;
    color: $white;
    border-radius: 50%;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover, .react-datepicker__year-text--selected:hover, .react-datepicker__year-text--in-selecting-range:hover, .react-datepicker__year-text--in-range:hover{
    background-color: $secondary;
    color: $white;
    border-radius: 50%;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected{
    background-color: $secondary;
    color: $white;
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover{
    background: $secondary;
}
.react-datepicker__header--time{
    padding: 36px 0;
}

.banner-img{
    // max-width: 440px;
}
.banner-image-main{
    // border: 21px solid #5F0D61;
    // border-radius: 285px;
    // background: #D9D9D9;
    // position: absolute;
    // bottom: -44px;
    position: relative;
    max-width: 450px;
    margin-top: 60px;
    // margin:0 auto;
    // @media (max-width: 1200px) {
    //     position: relative;
    // }
    @media (max-width: 767px) {
        bottom: 0;
        margin: 30px 0;
    }
    img{
        @media (max-width: 767px) {
            max-width: 350px;
        }
    }
    .icon-image{
        @media (max-width: 767px) {
            max-width: 20px;
        }
    }
}
.minw-173{
    min-width: 173px;
}
.btn-info{
    font-size: 18px;
    @media (max-width: 991px) {
        font-size: 16px;
      }
}
.event-buttons{
    @media (max-width: 767px) {
        flex-wrap: wrap;
    }
    li{
        margin-bottom: 10px;
    }
}
.banner-image-wrapper{
    .ct-text{
        padding: 8px 18px;
        background: $white;
        box-shadow: 0px 24px 50px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        position: absolute;
        display: flex;
        align-items: center;
        @media (max-width: 767px) {
            padding: 5px 12px;
          }
        img{
            position: relative;
        }
        &.ct-1{
            left: -7%;
            top: -10%;
            @media (max-width: 1024px) {
                left:0;
              }
        }
        &.ct-2{
            right: -5%;
            top: 12%;
            @media (max-width: 1024px) {
                right:0;
              }
        }
        &.ct-3{
            left: -20%;
            bottom: 43%;
            @media (max-width: 1024px) {
                left:0;
              }
        }
        &.ct-4{
            right: -18%;
            bottom: 26%;
            @media (max-width: 1024px) {
                right:0;
              }
        }
        h3{
            font-weight: 400;
            font-size: 16px;
            line-height: 1.6;
            color: #2E2E2E;
            font-family: 'Poppins', sans-serif;
            @media (max-width: 767px) {
                font-size: 14px;
                line-height: 22px;
              }
        }
    }
}


:root{
    @include media-breakpoint-up(lg) {
        --descHeight: 160px;
    }
}
.play-description{
    height: var(--descHeight);
    @include media-breakpoint-down(lg) {
        text-align: left;
    }
    h3{
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 24px;
        line-height: 36px;

    }
    p{
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;  
        overflow: hidden;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
        color: #000000;
        @include media-breakpoint-down(lg) {
            text-align: left;
        }
    }
}
.play-boxes{
    &:nth-child(even){
        @include media-breakpoint-up(lg) {
        margin-top: var(--descHeight);
        .play-image{
            padding-top: 75px;
        }
    }
    .play-image{
        @include media-breakpoint-down(lg) {
            transform: rotate(-90deg);
        }
    }
    }
    &:nth-child(odd){
        @include media-breakpoint-up(lg) {
            margin-bottom: var(--descHeight);
            .play-image{
                padding-bottom: 75px;
            }
        }
        .play-image{
            @include media-breakpoint-down(lg) {
                transform: rotate(90deg);
            }
        }
    }
}
.play-description{
    @include media-breakpoint-down(lg) {
        order: 1;
    }
}
.play-image{
    @include media-breakpoint-down(lg) {
        order: 0;
        margin-right: 20px;
        flex-shrink: 0;
        width: 180px;
    }
    @include media-breakpoint-down(md) {
        width: 150px;
    }
    @include media-breakpoint-down(sm) {
        width: 120px;
    }
}

.text-skyblue{
    color: #57C9E8;
}
.text-lightgreen{
    color: #47D930;
}
.text-orange{
    color: #EAB577;
}
.text-yellow{
    color: #E7D177;
}
.text-darkgreen{
    color: #00B59A;
}
.text-blue{
    color: #23346C;
}



.how-to-play-section{
    h2{
        position: relative;
        display: inline-block;
        line-height: 1.2;
        &::after{
            width: 70%;
            display: flex;
            align-items: center;
            justify-content: center;
            background:#23346C;
            height: 3px;
            content: "";
            margin: 30px auto 70px;
            text-align: center;
            font-family: $headings-font-family;
            @include media-breakpoint-down(md) {
                margin: 30px auto;
            }
        }
    }
}


.minw-160{
    min-width: 160px;
}


.tabing-question{
    .nav-tabs{
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 40px;
        border: 1px solid #d3d3d3;
        .nav-item{
            .nav-link{
                border:none;
                border-radius: 0;
                background-color: $white;
                margin: 0;
                border-radius: 40px;
                padding: 13px 63px;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #000000;
                font-family: 'Poppins', sans-serif;
                @include media-breakpoint-down(sm) {
                    padding: 10px 18px;
                }
                &.active{
                    background-color: $primary;
                    color: $white;
                }
            }
            &:first-child{
                .nav-link{
                    border-radius: 40px 0 0 40px;
                }
            }
            &:last-child{
                .nav-link{
                    border-radius: 0 40px 40px 0;
                }
            }
        }
    }
    .accordion {
        .accordion-button{
            padding: 10px 15px 10px 20px;
        }
    }
}


.modal-body{
    padding: 20px 30px;
}
.modal-header{
    padding: 20px 30px;
}



.react-calendar__navigation {
    background: $secondary;
    border-radius: 10px 10px 0 0;
}
.react-calendar{
    border-radius: 10px;
}
.react-calendar__navigation button{
    color: $white;
}
.react-calendar__month-view__weekdays__weekday{
    color: $secondary;
}
.react-calendar__tile--active{
    background: $primary;
    border-radius: 4px;
}
.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus{
    background: $primary;
}
.react-calendar__tile--now{
    background: $secondary;
    color: $white;
    border-radius: 4px;
}
.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus{
    background: transparent;
}
.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus{
    background: $secondary;
}

.navbar-nav{
    .nav-link{
        color: $gray;
        font-size: 18px;
        line-height: 25px;
        padding: 6px 0 !important;
        margin: 0 16px;
        @include media-breakpoint-down(lg) {
            font-size: 20px;
        }
        border-bottom: 1px solid transparent;
        @media only screen and (max-width: 991px) {
            color: $black;
            margin: 4px 0;
        }
        &.active{
            border-bottom: 1px solid var(--primary-color);
            color:  $gray;
        }
        &.show {
            color: inherit;
        }
        &:focus {
            color: inherit;
        }
        &:hover{
            border-bottom: 1px solid var(--bs-primary);
            @media only screen and (max-width: 991px) {
                border-bottom: 1px solid var(--bs-primary);
                color: var(--bs-primary);
            }
        
        }
    }
}

.nav-link:not(:first-child){
    margin-left: 0;
}
// Quest SVG colors
.cityquestsvg {
    color: $success;
}
.homequestsvg {
    color: $warning;
}
.officequestsvg{
    
    color: $secondary;
}
.schoolquestsvg{
    color: $primary;
}
.halloweensvg{
    color: #9d0f6a;
}

// Quest bg colors
.dark-cityquest {
    background-color: $success;
}
.dark-homequest {
    background-color: $secondary;
}
.dark-officequest{
    background-color: $warning;
}
.dark-schoolquest{
    background-color: $primary;
}
.dark-hollowing{
    background-color: #9d0f6a;
}
// ============================= Logo Slider
.logo-slider{
    max-width: 1137px;
    margin: 0 auto;

    .logo-box{
        padding: 0 14px;
    }
   
    .slick-track{
        display: flex;
        align-items: center;
    }
    .slick-arrow{
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    .slick-next, .slick-prev{
      width: 13px;
      height: 18px;
      &::before{
        display: none;
      }
    }
    .slick-prev{
      background: url(../../../public/assets/images/arrow-left.svg);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      &:hover{
        background: url(../../../public/assets/images/arrow-left.svg);
        filter: invert(35%) sepia(100%) saturate(7500%) hue-rotate(263deg) brightness(94%) contrast(115%);
        background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      }
    }
    .slick-next{
      background: url(../../../public/assets/images/arrow-right.svg);
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      &:hover{
        background: url(../../../public/assets/images/arrow-right.svg);
        filter: invert(35%) sepia(100%) saturate(7500%) hue-rotate(263deg) brightness(94%) contrast(115%);
        background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      }
    }
  }
  .header-logo-full {
    width: 100%;
    max-width: 180px;
    display: inline-block;
}
.review-rating {
    margin: 0.5rem 0;
    color: black;
}

.is-circle {
    padding: calc(1rem - 0.5px) 1rem;
    border-radius: 10em;
}
.custome_h2 {
    font-size: 3rem;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0.5em;
    position: relative;
    line-height: 1.25;
}
.gameIcon {
   // filter: invert(94%) sepia(100%) saturate(3780%) hue-rotate(279deg) brightness(98%) contrast(74%);
    max-width: 70px;
    max-height: 70px;
    background-color: #712973;
    border-radius: 50%;
    padding: 1rem;
}
.hiddenRadio {
    visibility: hidden;
}

.accordion-faq{
    .accordion-item-faq{
        border-radius:0px !important;
        border:1px solid #d3d3d3;
        overflow: hidden;
    }
    .accordion-button{
        border-top-left-radius:0px !important;
        border-top-right-radius:0px !important;
        box-shadow:none;
        background: #fff !important;
        font-size: 14px;
        font-family: 'Poppins';
        font-weight: 600;
        letter-spacing: 0.02em;
        color: #757575;
        padding:10px 12px;
        &::after{
            width: 23px;
            height: 23px;
            border: 1.5px solid #D7D7D7;
            border-radius: 6px;
            background-size: 16px;
            background-position: center;
        }
       
        &:focus{
            box-shadow:none;
        }
    }
    .accordion-collapse{
      background: #fff !important;
        &.show{
            border-radius: 0 0 0px 0px !important;
        }
    }
}
.accordion-item:last-of-type .accordion-button.collapsed{
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.slick-next{
    right: 0;
    &::before{
        opacity: 1;
    }
}
.slick-prev{
    left: 0;
    &::before{
        opacity: 1;
    }
}